exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-mdx": () => import("./../../../src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-mdx": () => import("./../../../src/pages/projects.mdx" /* webpackChunkName: "component---src-pages-projects-mdx" */),
  "component---src-pages-setup-mdx": () => import("./../../../src/pages/setup.mdx" /* webpackChunkName: "component---src-pages-setup-mdx" */),
  "component---src-pages-wiki-tsx": () => import("./../../../src/pages/wiki.tsx" /* webpackChunkName: "component---src-pages-wiki-tsx" */),
  "component---src-templates-prose-tsx": () => import("./../../../src/templates/prose.tsx" /* webpackChunkName: "component---src-templates-prose-tsx" */),
  "component---src-templates-wiki-tsx": () => import("./../../../src/templates/wiki.tsx" /* webpackChunkName: "component---src-templates-wiki-tsx" */)
}

