import * as React from "react"
import { Box, Stack, Flex, Link as ExternalLink } from "@chakra-ui/react"
import { useFooterNavigation } from "../../hooks/use-footer-navigation"
import { Link } from "../link"
import { FullWidthContainer } from "./full-width-container"

export const Footer: React.FC = () => {
  const footerNavigation = useFooterNavigation()

  return (
    <FullWidthContainer variant="dark">
      <Box as="footer" py={16} role="contentinfo">
        <Stack direction="column" spacing={16}>
          <Flex flexDirection={[`column`, `row`]} flexWrap="wrap" justifyContent="space-between">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="150"
              height="150"
              viewBox="0 0 700 700"
              preserveAspectRatio="xMidYMid meet"
            >
              <g transform="translate(0,700) scale(0.1,-0.1)" fill="currentColor" stroke="none">
                <path d="M110 6331 c0 -5 23 -47 52 -93 28 -46 209 -344 401 -663 193 -319 501 -830 685 -1135 184 -305 361 -598 392 -650 31 -52 182 -302 335 -555 153 -253 376 -622 495 -820 198 -329 293 -486 745 -1235 81 -135 183 -305 227 -378 44 -72 83 -132 86 -132 6 0 196 318 769 1285 62 105 272 458 467 785 194 327 350 598 347 603 -4 4 -223 6 -486 5 l-480 -3 -72 -120 c-40 -66 -179 -300 -310 -519 -131 -220 -242 -401 -248 -402 -5 -2 -219 342 -475 763 -256 421 -571 939 -700 1152 -578 948 -759 1247 -765 1264 -7 16 89 17 1919 17 1059 0 1926 -4 1926 -9 0 -4 -85 -149 -188 -322 l-188 -314 -1234 -5 c-894 -4 -1234 -8 -1233 -16 1 -6 83 -143 181 -305 99 -162 214 -351 257 -421 l77 -128 1203 0 1203 0 263 443 c385 649 388 654 749 1262 182 308 342 577 356 597 13 21 24 42 24 48 0 7 -1089 10 -3390 10 -2059 0 -3390 -4 -3390 -9z" />
              </g>
            </svg>
            {footerNavigation.map((section) => {
              const { heading } = section

              return (
                <Flex key={heading.name} flexDirection="column" alignItems="flex-start" mb={[8, 0]}>
                  {heading.link ? (
                    <Link to={heading.link} p={1} color="white" fontSize={[`1.125rem`, null, `1.3125rem`]} mb={[2, 3]}>
                      {heading.name}
                    </Link>
                  ) : (
                    <Box color="white" p={1} fontSize={[`1.125rem`, null, `1.3125rem`]} mb={[2, 3]}>
                      {heading.name}
                    </Box>
                  )}
                  <Flex flexDirection={[`row`, `column`]} alignItems="flex-start" flexWrap={[`wrap`, `nowrap`]}>
                    {section.items.map((item) => (
                      <React.Fragment key={item.link}>
                        {item.isExternal ? (
                          <ExternalLink mr={[2, 0]} p={1} href={item.link}>
                            {item.name}
                          </ExternalLink>
                        ) : (
                          <Link mr={[2, 0]} p={1} to={item.link}>
                            {item.name}
                          </Link>
                        )}
                      </React.Fragment>
                    ))}
                  </Flex>
                </Flex>
              )
            })}
          </Flex>
          <Flex flexDirection={[`column`, `row`]} textAlign="center" alignItems="center" justifyContent="center">
            <div>&copy; {new Date().getFullYear()} by Thomas Lai.</div>
          </Flex>
        </Stack>
      </Box>
    </FullWidthContainer>
  )
}
