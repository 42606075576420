import * as React from "react"
import { Flex } from "@chakra-ui/react"
import { useLocation } from "@reach/router"
import { useDistinctCategories } from "../../hooks/use-distinct-categories"
import { Link } from "../link"
import { Navigation } from "./navigation"
import { FullWidthContainer } from "./full-width-container"
import { Spacer } from "./spacer"

const Logo: React.FC = () => (
  <Link to="/" transform="scale(1)" _hover={{ transform: `scale(1.1)` }} aria-label="Back to homepage">
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 700 700"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0,700) scale(0.1,-0.1)" fill="currentColor" stroke="none">
        <path d="M110 6331 c0 -5 23 -47 52 -93 28 -46 209 -344 401 -663 193 -319 501 -830 685 -1135 184 -305 361 -598 392 -650 31 -52 182 -302 335 -555 153 -253 376 -622 495 -820 198 -329 293 -486 745 -1235 81 -135 183 -305 227 -378 44 -72 83 -132 86 -132 6 0 196 318 769 1285 62 105 272 458 467 785 194 327 350 598 347 603 -4 4 -223 6 -486 5 l-480 -3 -72 -120 c-40 -66 -179 -300 -310 -519 -131 -220 -242 -401 -248 -402 -5 -2 -219 342 -475 763 -256 421 -571 939 -700 1152 -578 948 -759 1247 -765 1264 -7 16 89 17 1919 17 1059 0 1926 -4 1926 -9 0 -4 -85 -149 -188 -322 l-188 -314 -1234 -5 c-894 -4 -1234 -8 -1233 -16 1 -6 83 -143 181 -305 99 -162 214 -351 257 -421 l77 -128 1203 0 1203 0 263 443 c385 649 388 654 749 1262 182 308 342 577 356 597 13 21 24 42 24 48 0 7 -1089 10 -3390 10 -2059 0 -3390 -4 -3390 -9z" />
      </g>
    </svg>
  </Link>
)

type HeaderProps = {
  subnavigation?: React.ReactNode
}

export const Header: React.FC<HeaderProps> = ({ subnavigation = undefined }) => {
  const categorySlugs = useDistinctCategories()
  const location = useLocation()
  const isCategoryPage = categorySlugs.includes(location.pathname)
  const variant = subnavigation ? `navigationWithSub` : `navigation`
  const height = subnavigation ? `navigationWithSubHeight` : `navigationHeight`

  return (
    <>
      <FullWidthContainer variant={isCategoryPage ? `fullBleed` : variant} height={height}>
        <Flex as="header" alignItems="center" justifyContent="space-between" py="13px">
          <Logo />
          <Navigation />
        </Flex>
        {subnavigation}
      </FullWidthContainer>
      {!isCategoryPage && <Spacer size={height} axis="vertical" />}
    </>
  )
}
